import React from "react";
import Layout from "../components/common/Layout";
import { Seo } from "../components/meta/Seo";
import ContactPage from "../components/elements/ContactPage";

const contact = () => {
  return (
    <Layout>
      <Seo title="Contact" />
      <div className="container">
        <ContactPage />
      </div>
    </Layout>
  );
};

export default contact;
