import React from "react";
import * as styles from "./ContactPage.module.css";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import ContactForm from "./ContactForm";

const ContactPage = () => {
  const contactData = useStaticQuery(graphql`
    query {
      prismicPage(data: { page_title: { text: { eq: "Contact" } } }) {
        id
        data {
          page_feature_image {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
          page_title {
            text
          }
        }
      }
    }
  `);
  return (
    <div className={styles.contact_wrapper}>
      <div className={styles.image_wrapper}>
        <GatsbyImage
          className="img-responsive"
          image={
            contactData.prismicPage.data.page_feature_image.gatsbyImageData
          }
          alt="img"
        />
      </div>
      <div className={styles.form_wrapper}>
        <ContactForm styles={styles} />
      </div>
    </div>
  );
};

export default ContactPage;
