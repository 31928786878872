import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import { useState } from "react";

const ContactForm = ({ styles }) => {
  const [state, handleSubmit] = useForm("xvoyvedn");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [company, setCompany] = useState("");

  if (state.succeeded) {
    return <p>Thanks for joining!</p>;
  }
  return (
    <form className={styles.contact_form} onSubmit={handleSubmit}>
      <label htmlFor="your-name" className={styles.label}>
        First Name
        <input
          type="text"
          name="your-name"
          className="firstName"
          tabIndex="1"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </label>
      <label htmlFor="your-email" className={styles.label}>
        Email
        <input
          type="email"
          name="your-email"
          id="email"
          className="email"
          placeholder=""
          tabIndex="3"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </label>
      <label htmlFor="company" className={styles.label}>
        Company(Optional)
        <input
          type="text"
          name="company"
          id="company"
          className="company"
          placeholder=""
          tabIndex="3"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
        />
      </label>
      <label htmlFor="your-message" className={styles.label}>
        Message
        <textarea
          type="textarea"
          rows="5"
          className={styles.message}
          name="your-message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
      </label>
      <ValidationError prefix="Message" field="message" errors={state.errors} />
      <button
        type="submit"
        disabled={state.submitting}
        className="primary_button"
        value="SUBMIT"
      >
        Submit
      </button>
    </form>
  );
};

export default ContactForm;
